<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title"> Information</h4>
                        <br>
                            <div class="row form-group">
                                <div class="col-md-4 mb-3" >
                                    <div class="d-inline-flex">
                                        <div class="mr-3 align-self-center h6">Name :</div>
                                        <div class="mr-3 align-self-center h6"> {{adminData.name}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3" >
                                    <div class="d-inline-flex">
                                        <div class="mr-3 align-self-center h6">Surname :</div>
                                        <div class="mr-3 align-self-center h6"> {{adminData.surname}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="d-inline-flex">
                                        <div class="mr-3 align-self-center h6">Country :</div>
                                        <div class="mr-3 align-self-center h6"> {{adminData.country.country_name}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-inline-flex">
                                        <div class="mr-3  align-self-center h6">Username :</div>
                                        <div class="mr-3 align-self-center h6"> {{adminData.username}}</div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-inline-flex">
                                        <div class="mr-3 align-self-center h6">Email :</div>
                                        <div class="mr-3 align-self-center h6"> {{adminData.email}}</div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="d-inline-flex">
                                        <div class="mr-3 align-self-center h6">Since :</div>
                                        <div class="mr-3 align-self-center h6"> {{adminData.created_at}}</div>
                                    </div>
                                </div> -->
                            </div>
                            <button class="btn btn-primary" @click.prevent="edit" type="submit">Edit</button>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_get} from "../../../helpers/helper";


    /**
     * Form validation component
     */
    export default {
        page: {
            title: "View Admin",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader},

        data() {
            return {
                date:"",
                id:"",
                adminData:null,
                title: "View Admin",
                items: [
                    {
                        text: "View All Admin",
                        href: "/admins"
                    },
                    {
                        text: "View Admin",
                        active: true
                    }
                ],
            }
        },
        methods:{
            edit(){
                window.location.href = 'edit-admin/'+this.$route.params.id
            }

        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id),
            axios_get("/user/"+this.id,{},(d)=>{
                console.log(d);
                this.adminData=d;
                console.log(this.adminData);
                this.date=Date.parse(this.adminData.created_at)
                console.log(this.date);
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        }
    }
</script>

<style scoped>

</style>